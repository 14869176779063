import React from 'react';

import styles from './food-card.module.css';

export const FoodCard = ({ img, title, dataTab, label, children }) => (
  <div className={styles.foodCard} data-tab-active={dataTab}>
    <div className={styles.foodCardTop}>
      <img src={img} className={styles.foodCardImg} alt="img" />
      <span className={styles.label}>{label}</span>
    </div>
    <div className={styles.foodCardBody}>
      <h5 className={styles.foodCardTitle}>{title}</h5>
      <div>{children}</div>
    </div>
  </div>
)
